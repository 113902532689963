<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="80"
      round
      :needBtnLoading="true"
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
    </GlobalForm>
  </div>
</template>

<script>
import _api from "@/utils/request";
import moment from "moment";
export default {
  name: "Form",
  data() {
    return {
        initData: {
          entryTime: [
            moment().day(moment().day() - 31).format("YYYY-MM-DD 00:00:00"),
            moment().format("YYYY-MM-DD 23:59:59"),
          ],
        },
        pickerMinDate: null,
        pickerMaxDate: null,
        day31: 30 * 24 * 3600 * 1000,
        formItemList: [
            {
              labelName: "门店商家",
              key: "companyId",
              type: "selectFilterable",
              placeholder: "请选择",
              lableWidth: "78px",
              clear: true,
              option: [],
              clearFun: () => false,
            },
            {
                labelName: "活动名称",
                key: "activityId",
                type: "selectFilterable",
                placeholder: "请选择",
                lableWidth: "78px",
                clear: true,
                option: [],
                clearFun: () => false,
            },
            {
                key: "entryTime",
                type: "pickerOptions",
                labelName: "创建时间",
                clearable: true,
                lableWidth: '50',
                placeholder: "请选择时间",
                valueFormat: "timestamp",
                pickerOptions: {
                    onPick: ({ maxDate, minDate }) => {
                    if (minDate && this.pickerMinDate) {
                        this.pickerMinDate = null;
                    } else if (minDate) {
                        this.pickerMinDate = minDate.getTime();
                    }
                    },
                    disabledDate: (time) => {
                    if (this.pickerMinDate) {
                        return (
                        time.getTime() > this.pickerMinDate + this.day31 ||
                        time.getTime() < this.pickerMinDate - this.day31
                        );
                    }
                    return false;
                    },
                },
            },
        ],
    };
  },
  created() {
    this.getStoreList()
    this.activitySelectList();
    this.handleConfirm(this.initData);
  },
  methods: {
    // 门店商家下拉
    getStoreList() {
      _api.getSelectList().then((res) => {
        if (res.code === 1) {
          const Index = this.formItemList.findIndex(
            (v) => v.key === "companyId"
          );
          this.formItemList[Index].option = res.data.map((r) => {
            return {
              label: r.name,
              value: r.id,
            };
          });        
        }
      });
    },
    // 活动下拉
    activitySelectList() {
      _api.activitySelect({companyId:""}).then((res) => {
        if (res.code === 1) {
          const Index = this.formItemList.findIndex(
            (v) => v.key === "activityId"
          );
          this.formItemList[Index].option = res.data.map((r) => {
            return {
              label: r.name,
              value: r.id,
            };
          });        
        }
      });
    },
    handleConfirm(data,cd) {
      let SeachParams = {
        companyId:data&&data.companyId ? data.companyId : "",
        activityId:data&&data.activityId ? data.activityId : "",
        startTime:data&&data.entryTime ? moment(data.entryTime[0]).format("x") : "",
        endTime: data&&data.entryTime ? moment(data.entryTime[1]).format("x") : "",
        pageNum: 1,
        pageSize: 10,
        disuseLoding: () => {
          if(typeof cd ==='function'){
             cd();
          }
        },
      };
      this.SeachParams = SeachParams;
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.el_from {
  margin-left: 30px;
  .form_flex_cont {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
  }
  .drawerWith{
    width: 500px;
  }
  .timeStyle{
    margin-top: 15px;
  }
}
</style>
