<template>
  <div class="GlobalTable">
    <div class="button_top">
      <div class="faultBtn">
        <div
          :class="auditStatus === '00' ? 'active' : 'none'"
          @click="btnClick('00')"
        >
          未开始({{ applyNumber.unStartNum || 0 }})
        </div>
        <div
          :class="auditStatus === '01' ? 'active' : 'none'"
          @click="btnClick('01')"
        >
          进行中({{ applyNumber.ingNum || 0 }})
        </div>
        <div
          :class="auditStatus === '10' ? 'active' : 'none'"
          @click="btnClick('10')"
        >
          已结束({{ applyNumber.overNum || 0 }})
        </div>
      </div>
    </div>
    <GlobalTable
      ref="GlobalTable"
      v-if="refresh"
      v-loading="loading"
      :columns="tableColumns"
      :data="seachDataList"
      :currentPage="page.pageNum"
      :total="page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="活动名称" slot="activityName" align="center">
        <template slot-scope="{ row }">
          <el-tooltip effect="dark" :content="row.activityName" placement="top">
            <div class="Remarks">
              <span>{{ row.activityName }}</span>
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        label="活动海报"
        width="130px"
        slot="activityPoster"
        align="center"
      >
        <template slot-scope="{ row }">
          <img
            style="width: 380px; height: 54px; object-fit: contain"
            :src="row.activityPoster"
            @click="seeSampleImage(row.activityPoster)"
            alt=""
          />
        </template>
      </el-table-column>
      <el-table-column
        label="换新商品"
        width="130px"
        slot="productNum"
        align="center"
      >
        <template slot-scope="{ row }">
          <div class="wrapStyle">
            <span
              style="
                color: #0981ff;
                cursor: pointer;
                text-decoration: underline;
              "
              @click="goodsDialog(row, 'goods')"
              >{{ row.productNum }}</span
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="赠送商品"
        width="130px"
        slot="giftGoods"
        align="center"
      >
        <template slot-scope="{ row }">
          <div class="wrapStyle">
            <span
              style="
                color: #0981ff;
                cursor: pointer;
                text-decoration: underline;
              "
              @click="goodsDialog(row, 'gifts')"
              >{{ row.giftNum }}</span
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="参与门店"
        width="130px"
        slot="storeNum"
        align="center"
      >
        <template slot-scope="{ row }">
          <div class="wrapStyle">
            <span
              style="
                color: #0981ff;
                cursor: pointer;
                text-decoration: underline;
              "
              @click="storeDialog(row)"
              >{{ row.storeNum }}</span
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="活动开始时间"
        width="130px"
        slot="startTime"
        align="center"
      >
        <template slot-scope="{ row }">
          <div class="wrapStyle">
            <span v-if="row.startTime != null">{{ row.startTime }}</span>
            <span v-else>{{ row.activityStart }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="活动结束时间"
        width="130px"
        slot="endTime"
        align="center"
      >
        <template slot-scope="{ row }">
          <div class="wrapStyle">
            <!-- <span v-if="row.endTime != null || row.activityEnd != null">{{ row.endTime || row.activityEnd }}</span> -->
            <span v-if="row.endTime != null">{{ row.endTime }}</span>
            <span v-else>商品售完自动结束</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="上架人"
        width="130px"
        slot="startStaffPcName"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>{{ row.startStaffPcName || "自动上架" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="换新订单"
        width="130px"
        slot="orderNum"
        align="center"
      >
        <template slot-scope="{ row }">
          <div class="wrapStyle">
            <span
              v-if="row.orderNum"
              style="
                color: #0981ff;
                cursor: pointer;
                text-decoration: underline;
              "
              @click="goRenewOrder(row)"
              >{{ row.orderNum }}</span
            >
            <span v-else>--</span>
          </div>
        </template>
      </el-table-column>
    </GlobalTable>
    <!-- 商品列表 -->
    <el-dialog
      :title="goodsListTitle"
      :visible.sync="goodsVisible"
      :close-on-click-modal="false"
      width="1000px"
      @close="btnClose"
    >
      <div class="flex staff-list-form" style="margin-bottom: 20px">
        <div class="flex" style="margin-right: 10px">
          <!-- <span style="width: 56px">商品名称</span>
            <el-input
              v-model="productName"
              size="small"
              clearable
              placeholder="请输入商品名称"
            ></el-input>
          </div> -->
          <span style="width: 60px">商品名称</span>
          <el-select
            v-model="productId"
            filterable
            size="small"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in productList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div style="margin-right: 10px">
          <span style="width: 93px">所属分类</span>
          <el-select
            v-model="goodsClass"
            filterable
            size="small"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in goodClassList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <span style="width: 93px">商品状态</span>
          <el-select
            v-model="goodsState"
            filterable
            size="small"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in goodsStateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <el-button
          type="primary"
          style="margin-left: 10px"
          size="small"
          @click="searchGoods"
        >
          查询
        </el-button>
      </div>
      <GlobalTable
        ref="GlobalTable"
        :loading="goodsLoading"
        :columns="goodsColumns"
        :data="dialogGoodsList"
        :currentPage="goodsPage.pageNum"
        :total="goodsPage.total"
        @handleCurrentChange="GoodsSearch"
      >
        <el-table-column
          width="120"
          label="商品状态"
          slot="shopState"
          align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="row.state == '00'">未上架</span>
            <span v-else-if="row.state == '03'">在售中</span>
            <span v-else-if="row.state == '05'">已售完</span>
            <span v-else-if="row.state == '10'">已到期</span>
          </template>
        </el-table-column>
        <el-table-column
          label="成本(元)"
          slot="cost"
          align="center"
          v-if="goodsType !== 'goods'"
        >
          <template slot-scope="{ row }">
            <span>{{ row.cost }}</span>
          </template>
        </el-table-column> 
        <el-table-column
          label="售价(元)"
          slot="sellPrice"
          v-if="goodsType !== 'goods'"
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{ row.sellPrice }}</span>
          </template>
        </el-table-column>
        <el-table-column
        v-if="goodsType !== 'goods'"
          label="赠品条件"
          slot="giftRuleType"
          align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="row.giftRuleType == '00'">不压价也可赠送</span>
            <span v-else-if="row.giftRuleType == '01'"
              >压价金额≥赠品成本时可赠送</span
            >
            <span v-else-if="row.giftRuleType == '02'"
              >压价金额≥赠品售价时可赠送</span
            >
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer">
        <el-button size="small" @click="btnClose">关闭</el-button>
      </span>
    </el-dialog>
    <!-- 门店列表 -->
    <el-dialog
      :title="storeListTitle"
      :visible.sync="storeVisible"
      :close-on-click-modal="false"
      width="1250px"
      @close="btnClose"
    >
      <div class="flex staff-list-form" style="margin-bottom: 20px">
        <div class="flex" style="margin-right: 10px">
          <span style="width: 60px">门店名称</span>
          <el-input
            v-model="storeName"
            size="small"
            clearable
            placeholder="请输入门店名称"
          ></el-input>
        </div>
        <div style="margin-right: 10px">
          <span style="width: 60px">区域/总监</span>
          <el-select
            v-model="storePerson"
            filterable
            multiple
            size="small"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in storePersonList"
              :key="item.staffId"
              :label="item.staffName"
              :value="item.staffId"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <span style="width: 60px">门店标签</span>
          <el-select
            v-model="storeTag"
            filterable
            multiple
            size="small"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in storeTagList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <span style="width: 60px; margin-left: 10px">门店状态</span>
          <el-select
            v-model="storeState"
            filterable
            size="small"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in storeStateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <el-button
          type="primary"
          style="margin-left: 10px"
          size="small"
          @click="searchStore"
        >
          查询
        </el-button>
      </div>
      <GlobalTable
        ref="GlobalTable"
        :loading="storeLoading"
        :columns="storeColumns"
        :data="dialogStoreList"
        :currentPage="storePage.pageNum"
        :total="storePage.total"
        @handleCurrentChange="StoreSearch"
      >
        <el-table-column label="门店标签" slot="tagList" align="center">
          <template slot-scope="{ row }">
            <span class="tagStyle" v-for="item in row.tagList" :key="item.id">{{
              item.tagName
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="门店启用状态" slot="isEnable" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.isEnable ? "启用中" : "已禁用" }}</span>
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer">
        <el-button size="small" @click="btnClose">关闭</el-button>
      </span>
    </el-dialog>
    <!-- 查看图片 -->
    <el-image-viewer
      :zIndex="99999"
      v-if="showViewer"
      :on-close="
        () => {
          imgList = [];
          showViewer = false;
        }
      "
      :url-list="imgList"
    />
  </div>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import _api from "@/utils/request";

export default {
  name: "Table",
  components: { ElImageViewer },
  data() {
    return {
      btnLoading: false,
      // 海报放大
      imgList: [],
      showViewer: false,
      // end
      goodsVisible: false,
      storeVisible: false,
      goodsListTitle: "",
      storeListTitle: "",
      addedGoods: 0,
      addedStore: 0,
      goodsLoading: false,
      storeLoading: false,
      currentRow: {},
      goodsType: false,
      dialogGoodsList: [],
      dialogStoreList: [],
      storePage: {
        pageNum: 1,
        total: 0,
        pageSize: 10,
      },
      goodsPage: {
        pageNum: 1,
        total: 0,
        pageSize: 10,
      },
      goodsColumns: [
        { label: "商品名称", prop: "productName" },
        { label: "商品分类", prop: "typeName" },
        { slotName: "shopState" },
        { label: "添加时间", prop: "createTime" },
        { slotName: "cost" },
        { slotName: "sellPrice" },
        { label: "商品数量", prop: "stock" },
        { slotName: "giftRuleType" },
      ],
      storeColumns: [
        { label: "区域/总监", prop: "inspectorName" },
        { slotName: "tagList" },
        { label: "门店名称", prop: "storeName" },
        { slotName: "isEnable" },
        { label: "添加时间", prop: "createTime" },
      ],
      goodsParams: {
        id: "",
      },
      storeParams: {
        id: "",
      },
      productId: "",
      productList: [],
      goodClassList: [],
      goodsStateList: [
        {
          label: "未上架",
          value: "00",
        },
        {
          label: "在售中",
          value: "03",
        },
        {
          label: "已售完",
          value: "05",
        },
        {
          label: "已过期",
          value: "10",
        },
      ],
      // productName:"",
      goodsClass: "",
      goodsState: "",
      storeName: "",
      storePerson: [],
      storeTag: [],
      storePersonList: [],
      storeTagList: [],
      storeState: null,
      storeStateList: [
        {
          label: "启用中",
          value: true,
        },
        {
          label: "已禁用",
          value: false,
        },
      ],
      refresh: true,
      loading: false,
      seachDataList: [],
      auditStatus: "00",
      command: "",
      applyNumber: {},
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      middleColumns: [
        { label: "商户名称", prop: "companyName" },
        { label: "活动编码", prop: "activityNo" },
        { slotName: "activityName" }, //活动名称
        { slotName: "activityPoster" }, //活动海报
        { slotName: "productNum" }, //换新商品
        { slotName: "giftGoods" }, //赠送商品
        { slotName: "storeNum" }, //参与门店
        { label: "创建人", prop: "createStaffPcName" },
        { label: "创建时间", prop: "createTime" },
        { slotName: "startTime" }, //活动开始时间
        { slotName: "endTime" }, //活动结束时间
      ],
      tableColumns: [],
    };
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  created() {
    this.tableColumns = [...this.middleColumns];
    this.handleCurrentChange();
  },
  watch: {
    SeachParams(newVal) {
      this.page.pageNum = 1;
      this.handleCurrentChange();
    },
  },
  methods: {
    // 查看示例图大图
    seeSampleImage(posterImg) {
      this.imgList = [];
      this.showViewer = true;
      this.imgList.push(posterImg);
    },
    searchGoods() {
      this.goodsPage.pageNum = 1;
      this.searchSettingGoods();
    },
    GoodsSearch(val) {
      if (val) {
        this.goodsPage.pageNum = val;
      }
      this.searchSettingGoods();
    },
    // 商品查询
    searchSettingGoods() {
      this.goodsLoading = true;
      const params = {
        activityId: this.currentRow.activityId,
        productId: this.productId,
        pageNum: this.goodsPage.pageNum,
        pageSize: this.goodsPage.pageSize,
        isOpenGift: this.goodsType == "goods" ? false : true,
        state: this.goodsState,
        typeCode: this.goodsClass,
      };
      _api.getRenewProductsList(params).then((res) => {
        if (res.code === 1) {
          this.dialogGoodsList = res.data.records;
          this.addedGoods = res.data.total;
          this.goodsListTitle =
            this.goodsType == "goods"
              ? `已添加商品(${this.addedGoods})`
              : `已添加赠品(${this.addedGoods})`;
          this.goodsPage.pageNum = res.data.current;
          this.goodsPage.total = res.data.total;
          this.goodsLoading = false;
        }
      });
    },
    searchStore() {
      this.storePage.pageNum = 1;
      this.searchSettingStore();
    },
    StoreSearch(val) {
      if (val) {
        this.storePage.pageNum = val;
      }
      this.searchSettingStore();
    },
    // 门店查询
    searchSettingStore() {
      this.storeLoading = true;
      const params = {
        activityId: this.currentRow.activityId,
        inspectorIds: this.storePerson,
        isEnable: this.storeState,
        storeName: this.storeName,
        tagIds: this.storeTag,
        pageNum: this.storePage.pageNum,
        pageSize: this.storePage.pageSize,
      };
      _api.getRenewStoresList(params).then((res) => {
        if (res.code == 1) {
          this.dialogStoreList = res.data.records;
          (this.addedStore = res.data.total),
            (this.storeListTitle = `已添加门店(${this.addedStore})`);
          this.storePage.pageNum = res.data.current;
          this.storePage.total = res.data.total;
          this.storeLoading = false;
        }
      });
    },
    // 列表关闭
    btnClose() {
      this.productId = "";
      this.goodsClass = "";
      this.goodsState = "";
      this.storeName = "";
      this.storePerson = [];
      this.storeTag = [];
      this.storeState = null;
      this.addedStore = 0;
      this.addedGoods = 0;
      this.storePage = {
        pageNum: 1,
        total: 0,
      };
      this.goodsPage = {
        pageNum: 1,
        total: 0,
      };
      this.goodsVisible = false;
      this.storeVisible = false;
    },
    goodsDialog(row, type) {
      this.goodsType = type;
      this.currentRow = row;
      this.goodsVisible = true;
      this.productSelect(row.activityId, row.companyId);
      this.getGoodsClassSelectList();
      this.searchSettingGoods();
    },
    // 商品下拉
    productSelect(activityId, companyId) {
      _api.productSelect({ activityId, companyId }).then((res) => {
        console.log(111, res);
        this.productList = res.data;
      });
    },
    // 商品所属分类下拉
    getGoodsClassSelectList() {
      _api.activityTypeSelect({ companyId: this.companyId }).then((res) => {
        if (res.code === 1) {
          this.goodClassList = res.data;
        }
      });
    },
    storeDialog(row) {
      this.currentRow = row;
      this.storeVisible = true;
      this.getPersonSelectList();
      this.getStoreTagsSelectList();
      this.searchSettingStore();
    },
    // 门店区域/总监下拉
    getPersonSelectList() {
      _api
        .inspectorAreaSelect({ companyId: this.currentRow.companyId })
        .then((res) => {
          if (res.code === 1) {
            this.storePersonList = res.data;
          }
        });
    },
    // 门店标签下拉
    getStoreTagsSelectList() {
      _api
        .getStoreTagSelect({ companyId: this.currentRow.companyId })
        .then((res) => {
          if (res.code === 1) {
            this.storeTagList = res.data;
          }
        });
    },
    auditTrunon(row, type) {
      this.currentRow = row;
      console.log(12345, row, type);
      if (type == "edit") {
        this.drawerDialog = true;
        this.drawerInitData = {
          activityName: this.currentRow.activityName,
          userImg: this.currentRow.activityPoster,
          entryTime: this.currentRow.createTime,
          endTime: this.currentRow.activityStart,
          userSex: this.currentRow.userSex,
        };
      }
      this.auditShow = true;
    },
    // 跳转至换新订单
    goRenewOrder(row) {
      console.log(234, row);
      let routeData = this.$router.resolve({
        path: "/newMarkup/renewOrder",
        query: {
          type: "edit",
          activityId: row.activityId,
          companyId: row.companyId,
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 表格切换页面
    handleCurrentChange(val) {
      console.log("列表查询");
      if (val) {
        this.page.pageNum = val;
      }
      const baseRequest = {
        state: this.auditStatus,
        companyId: this.SeachParams.companyId,
        activityId: this.SeachParams.activityId,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };

      this.refresh = false;
      this.loading = true;
      _api.getRenewActivityList(baseRequest).then((res) => {
        if (res.code === 1) {
          
          this.seachDataList = res.data.list.records;
          this.applyNumber.ingNum = res.data.ingNum;
          this.applyNumber.unStartNum = res.data.unStartNum;
          this.applyNumber.overNum = res.data.overNum;
          this.page.total = res.data.list.total;
          this.page.pageNum = res.data.list.current;
          this.loading = false;
          this.refresh = true;
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding == "function") {
          this.SeachParams.disuseLoding();
        }
      });
    },
    btnClick(type) {
      this.auditStatus = type;
      this.refresh = false;
      const columns = [...this.middleColumns];
      if (type != "00") {
        columns.push(
          { slotName: "startStaffPcName" },
          { slotName: "orderNum" }
        );
      }
      this.tableColumns = columns;
      this.refresh = true;
      this.page.pageNum = 1;
      this.handleCurrentChange();
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  .wrapStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .staff-list-form {
    .el-input {
      width: 170px;
    }
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .button_top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .repair-conent {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .faultBtn {
    //width: 432px;
    height: 34px;
    background: #f9fbfd;
    border: 1px solid #c1d1ff;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    cursor: pointer;
    .active {
      text-align: center;
      color: white;
      padding: 0 20px;
      // width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      background: #0981ff;
      border-radius: 20px;
    }
    .none {
      text-align: center;
      color: #333;
      padding: 0 20px;
      // width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      border-radius: 20px;
    }
  }
  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }

  .remarks-tip {
    color: #0981ff;
    font-size: 16px;
    padding-left: 10px;
    border-left: 3px solid;
    margin-bottom: 10px;
  }
  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}

.call_accounts_info {
  margin: 10px 0;
}

.call_accounts_check {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 14px;
}

.call_accounts_result {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.el_from {
  margin-left: 30px;
  .form_flex_cont {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
  }
  .drawerWith {
    width: 500px;
  }
  .timeStyle {
    margin-top: 15px;
  }
}
.tagStyle {
  border: 1px solid #0981ff;
  border-radius: 10px;
  padding: 3px 10px;
  color: #0981ff;
  margin-right: 10px;
}
</style>
